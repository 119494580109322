import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section py-5"
        style={{
          background: "transparent",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto text-dark" md={12}>
              <h1
                className="text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                KEY TOPICS
              </h1>
            </Col>

            {content.map((c, i) => (
              <Col xs={11} lg={c.lg || 4} className="pr-0 pl-4">
                <div className="container-fluid row justify-content-center col-lg-11 col-xs-12 pr-0">
                  <Card
                    className="rounded-0 incomplete-box"
                    style={{
                      background: "transparent",
                      // border: "5px #5ab030 solid",
                    }}
                  >
                    <div className="container-fluid row ml-1 py-2">
                      <Col xs={2} className="align-self-center">
                        <img
                          src={require(`assets/icons/Asset ${c.image}`)}
                          style={{
                            minWidth: "80px",
                            marginLeft: "-60px",
                          }}
                          width="100%"
                          alt="about"
                          // className="mx-auto"
                        />
                      </Col>
                      <Col xs={10} className="align-self-center">
                        <p
                          className="text-400 mt-2 text-white"
                          dangerouslySetInnerHTML={{ __html: c.title }}
                        />
                      </Col>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

// Digital Twins for immersive experiences for people in building cities and ultra-luxury destinations
// Case studies on Digital Twin implementation for Construction for Saudi Arabia’s Giga projects– value of Digital Twins on each business line and understanding the ROI
// Panel discussions – Standards, Policies, Contracts & Procurement in the Digital Twin ecosystem

const content = [
  {
    title: `<span class="text-i">
   Formulating the Roadmap for Digital Twins:
</span>
<br /> <span class="text-i">What</span> is a Digital
Twin?
<br /> <span class="text-i">What is Not</span> a Digital Twin? `,
    image: "9.png",
    lg: 12,
  },
  {
    title: `
  <span class="text-i">Harnessing the power of Digital Twins with Gen AI.</span>`,
    image: "8.png",
    lg: 5,
  },
  {
    title: `
  <span class="text-info text-700">Data and Twins – </span>value of Data on business decisions across organizational functions 
  `,
    image: "10.png",
    lg: 5,
  },
  {
    title: `
    <span class="text-info text-700">Digital Twins for immersive experiences for people in building cities and infrastructure projects
  </span>
  `,
    image: "11.png",
    lg: 5,
  },
  {
    title: `
<span class="text-i">Panel discussions</span>– Exploring AI and
Data management in Construction via Digital Twins | Leadership commitment on achieving Vision 2030.
  `,
    image: "12.png",
    lg: 5,
  },
];

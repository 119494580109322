import React from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container className={""}>
          <Row className="justify-content-center">
            <Col md="12">
              <h1
                className=" text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                PAST EVENT HIGHLIGHTS
              </h1>
            </Col>
            <Col lg={6} className="border-right text-center">
              <img
                src={require(`assets/images/logo/past.png`)}
                width="80%"
                alt="about"
                className=""
              />
            </Col>
            <Col lg={6} className="align-self-center mt-5">
              <div className="container-fluid row">
                {stats.map((c) => (
                  <Col lg={6} xs={6} className="text-dark mb-5 d-flex ">
                    <img
                      src={require(`assets/icons/Asset ${c.image}`)}
                      width="100%"
                      style={{ width: size ? 120 : 60, height: size ? 120 : 60 }}
                      alt="dt22"
                    />
                    <div className="align-self-center ml-2">
                      <h3 className="text-900 text-g mt-1">{c.value}</h3>
                      <p
                        className="text-400 text-white"
                        style={{ fontSize: size ? "16px" : "10px" }}
                      >
                        {c.title}
                      </p>
                    </div>
                  </Col>
                ))}
              </div>
            </Col>
            <Col lg={12} className="text-center px-1 mt-5">
              <Button
                href="/past-events"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 400,
                    fontSize: "20px",
                  }}
                >
                  View More
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          background: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container className={""}>
          <Row className="justify-content-center">
            <Col md="12">
              <h1
                className=" text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                WHO CAN PARTICIPATE
              </h1>
            </Col>
            {content.map((c, i) => (
              <Col lg={4} xs={6} className="px-4">
                <Card
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: size ? "100px" : "none",
                  }}
                  className="my-4"
                >
                  <div className="d-flex">
                    <div
                      className="align-self-center rounded-circle"
                      style={{
                        minWidth: size ? 100 : 50,
                        height: size ? 100 : 50,
                        // background: i % 2 === 0 ? "#6ac2be" : "#00a4c3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px solid #ffffff",
                      }}
                    >
                      <img
                        src={require(`assets/icons/Asset ${c.image}`)}
                        style={{ width: size ? "90px" : "45px", height: size ? "90px" : "45px" }}
                        alt="about"
                        className=""
                      />
                    </div>
                    <div
                      className="d-flex align-items-center w-100"
                      style={{
                        borderTop: "5px solid #ec33f3",
                        borderRight: "5px solid #ec33f3",
                        borderBottom: "5px solid #ec33f3",
                        borderImage: "linear-gradient(to right, #fbba14, #ceb739) 1",
                        borderRadius: "30px",
                      }}
                    >
                      <p
                        className="text-400 py-2 pl-3 text-white align-self-center"
                        style={{ fontSize: size ? 16 : 9 }}
                      >
                        {c.title}
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1 mt-5">
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
                style={{ background: "#ceb739" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  REGISTER NOW
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "IT LEADERS", image: "28.png" },
  { title: "GIS LEADERS", image: "24.png" },
  { title: "CITY PLANNERS", image: "23.png" },
  { title: "PROJECT OWNERS", image: "25.png" },
  { title: "PROPERTY DEVELOPERS", image: "30.png" },
  { title: "FACILITY MANAGERS", image: "18.png" },
  { title: "DATA MANAGEMENT LEADERS", image: "18.png" },
  { title: "METAVERSE SOLUTION PROVIDERS", image: "22.png" },
  { title: "DIGITAL TWIN SOLUTION PROVIDERS", image: "27.png" },
  { title: "GOVERNMENT BODIES & MUNICIPALITIES", image: "19.png" },
  { title: "CONSTRUCTION MANAGEMENT CONSULTANTS", image: "29.png" },
  { title: "GEOSPATIAL & GIS SOLUTION PROVIDERS", image: "21.png" },
  { title: "ARCHITECTURE, ENGINEERING, CONTRACTORS FIRMS", image: "20.png" },
  { title: "BIM & DIGITAL CONSTRUCTION SOLUTION PROVIDERS", image: "26.png" },
];

const stats = [
  { title: "SPEAKERS", value: 35, image: "1.png" },
  { title: "DELEGATES", value: 210, image: "2.png" },
  { title: "SPONSORS", value: 8, image: "3.png" },
  { title: "PARTNERS", value: 4, image: "4.png" },
  { title: "CONFERENCE", value: "2 DAYS", image: "5.png" },
];

export default Attend;

import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";

import { sponsors } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU `} bgColor="#fff" />
      <div className="section" style={{ background: "#000" }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center text-white p-5 m-5">
              <h3>
                {id === "REGISTER" && (
                  <>
                    <span style={{ color: "white" }}>
                      We have received your interest for the Digital Twin Egypt 2025 Conference. A
                      member of our team will be in touch with you for further details about the
                      event.
                    </span>
                  </>
                )}
                {id === "BROCHURE" && (
                  <>
                    We have received your interest for the Digital Twin Egypt 2025 Conference. A
                    member of our team will be in touch with you for further details about the
                    event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    We have received your interest for the Digital Twin Egypt 2025 Conference. A
                    member of our team will be in touch with you for further details about the
                    event.
                  </>
                )}
                {id === "REPORT" && (
                  <>
                    We have received your interest for the Digital Twin Egypt 2025 Conference. A
                    member of our team will be in touch with you for further details about the
                    event.
                  </>
                )}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default ThankYou;

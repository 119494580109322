// reactstrap components
import React, { useEffect } from "react";

import Container from "reactstrap/lib/Container";

import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Card } from "reactstrap";

function Event({ text, location }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  useEffect(() => {
    const common = {
      containment: "#bgndVideo1",
      autoPlay: true,
      mute: true,
      startAt: 20,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "dpTW6ZP_k3U",
        ...common,
      },
    ];
    if (size) {
      window.jQuery("#bgndVideo1").YTPlaylist(videos, false);
    }
  }, []);

  return (
    <div className="section pt-4" style={{ backgroundColor: !size ? "#000" : "transparent" }}>
      {size && (
        <div
          style={{
            background: "#000",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: -100,
            marginLeft: "auto",
            marginRight: "auto",
            pointerEvents: "none",
            // marginTop: "3.5rem",
          }}
        >
          <div
            id="bgndVideo1"
            className="section player"
            style={{ background: "transparent" }}
          ></div>
        </div>
      )}
      {size && <div className="overlay-primary" />}
      <div
        className={size ? "page-header" : "section"}
        style={{ background: "transparent" }}
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Event Summary
              </h1>
            </Col>

            <Col md={6}>
              <p className="text-400 text-white">
                According to a JLL report, Egypt is the largest construction market in Africa and
                the third largest in the MENA region.
                <br />
                <br />
                With a spree of mega projects announced in Egypt with smart cities such as the
                Alamein City, New Cairo, infrastructure projects such as the High speed rail,
                Nuclear plants and power projects, there is a need to implement construction
                technologies across the lifecycle that prove to be cost and time compliant.
                <br />
                <br />
              </p>
            </Col>
            <Col md={6}>
              <p className="text-400 text-white">
                These revolutionary projects have complex teams involved globally that plan, design,
                view developments and maintain on a real time basis – via cutting edge systems that
                include Data management, AI and Digital Twins.
                <br />
                <br /> This conference will explore the Digital Twin ecosystem across the project
                lifecycle, its impact on each stage of the project and why should we implement it,
                giving infrastructure, smart cities and construction leaders an opportunity to
                network, learn and gain new insights and opportunities.
                <br />
                <br />
              </p>
            </Col>
            <Col lg={12}>
              <p className="text-400 text-white">
                With the success of our <span className="text-info text-700">DIGITAL TWIN</span>{" "}
                conferences in Dubai, Riyadh, London and Auckland since 2022 and the{" "}
                <span className="text-info text-700">
                  EGYPT DIGITAL CONSTRUCTION 2022 in Cairo, Saifee Events
                </span>{" "}
                is thrilled to launch the{" "}
                <span className="text-info text-700">EGYPT DIGITAL TWIN 2025</span> conference in
                Cairo.
              </p>
            </Col>
            <Col md={12} className="pr-0">
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "1.7rem",
                }}
              >
                Conference Format
              </h1>

              <div className={`container-fluid row `}>
                {content.map((c, i) => (
                  <Col lg={4} xs={6}>
                    <Card
                      style={{
                        background: "rgba(0,0,0,0)",
                      }}
                      className="text-center"
                    >
                      <div className="px-2  rounded-circle" style={{}}>
                        <img
                          src={require(`assets/icons/Asset ${c.image}`)}
                          style={{ width: "100px", height: "100px" }}
                          alt="about"
                          className=""
                        />
                      </div>
                      <div className="w-100" style={{ textAlign: size && "center" }}>
                        <p
                          className="text-400 py-2 pl-3 text-white"
                          style={{ fontSize: size ? 16 : 10 }}
                        >
                          {c.title}
                        </p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Event;

const content = [
  {
    title: "VIP KEYNOTES",
    image: "17.png",
  },
  {
    title: "EXHIBITION AND NETWORKING",
    image: "15.png",
  },

  {
    title: "TECHNICAL WORKSHOPS",
    image: "14.png",
  },
  {
    title: "DIGITAL TWIN CASE STUDIES AND PROOF OF CONCEPT PRESENTATIONS",
    image: "16.png",
  },
  {
    title: "THOUGHT-PROVOKING PANEL DISCUSSIONS",
    image: "14.png",
  },

  {
    title: "INTERNATIONAL CASE STUDIES AND PROOF OF CONCEPTS",
    image: "13.png",
  },
];
